<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <img 
          src="https://ik.imagekit.io/aryraditya/ceremonyku/corner_2x_sZXnEWtgp.png?tr=w-500"
          style="position: absolute; top: 0; right: 0; width: 75vw; max-width: 400px; transform: translateY(-20%) translateX(20%); opacity: .5"
        />
        <div class="text-center w-100">
          <div data-aos="fade-down" class="pic">
            <img
              :src="coverImage"
              class=""
            />
          </div>
          <div class="title mb-4">Undangan Mepandes</div>
          <!-- <span>from</span> -->
          <div class="mb-4 name"  data-aos="fade-down" data-aos-delay="200">
            Ani, Lusy & Yuda
          </div>

          <div class="guest-box mb-3 d-block mx-auto" data-aos="fade-up" data-aos-delay="600">
            <div class="guest-box__title">Kepada {{ $root.guest.title || 'Bpk/Ibu/Sdr/i' }}.</div>
            <div v-if="$root.guest.name" class="guest-box__name">
              <span>{{ $root.guest.name }}</span>
            </div>

            <div class="guest-box__note">Tanpa mengurangi rasa hormat, kami mengundang anda untuk hadir pada upacara Manusa Yadnya Mepandes putra-putri kami.</div>
          </div>

          <button
            data-aos-delay="800"
            data-aos="fade-up"
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" style="box-shadow: 2px 4px 20px rgba(171, 72, 113, 0.15); position:relative" />

      <!-- <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" /> -->
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <Footer ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00716.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1650373045637&tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00763.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1650373049187&tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00744.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1650373047911&tr=w-800";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
import Himbauan from "./components/Himbauan.vue";

import { setInterval } from "timers";
import Footer from "./components/Footer/Sabali.vue";

const SESSION = [
  ["2022-04-27 12:00", "Selesai"],
  // ["2021-09-09 16:30", "Selesai"]
];

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    Footer,
},
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      coverImage: 'https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00849.JPG?updatedAt=1650374470665&tr=w-644,h-644,fo-custom,cm-extract',
      location: "Jl. Antasura No.47, Br. Jurang Asri, Peguyangan Kangin, Kec. Denpasar Utara, Kota Denpasar - Bali",
      direction: 'https://goo.gl/maps/cxZh211xg7ua6oEPA',
      time: {
        start: this.$moment("2022-04-27 12:00"),
        end: "Selesai",
      },
      wedding: {
        // man: {
        //   name: "I Putu Sidik Karya, S.E",
        //   surname: "Sidik",
        //   parents: "I Nyoman Sadiyi & A. A. Manik Setiawati",
        //   description: "Putra pertama",
        //   address: " Br. Kesambi, Kesiman, Kertalangu, Denpasar Timur",
        //   photo:
        //     "https://ik.imagekit.io/aryraditya/ceremonyku/sidik-vidia/SA-7796.JPG?updatedAt=1648700932159&tr=w-400,h-501,fo-custom,cm-extract",
        // },
        // woman: {
        //   name: "Ni Made Vidia Dwi Antari, S.S",
        //   surname: "Vidia",
        //   parents: "I Ketut Jelantik & Made Adi Utari, S.H",
        //   address: "Br. Busung Yeh Kangin, Pemecutan, Denpasar Barat",
        //   description: "Putri kedua",
        //   photo:
        //     "https://ik.imagekit.io/aryraditya/ceremonyku/sidik-vidia/SA-7808.JPG?updatedAt=1648700913161&tr=w-488,h-611,fo-custom,cm-extract",
        // },
      },
      mepandes: [
        {
          name: 'Putu Budiani Kusumawati',
          photo: 'https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00820.JPG?updatedAt=1650373265732&tr=w-400,h-501,fo-custom,cm-extract',
        },
        {
          name: 'Made Lusy Rahmawati',
          photo: 'https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00832.JPG?updatedAt=1650373284788&tr=w-466,h-583,fo-custom,cm-extract',
        },
        {
          name: 'I Nyoman Yuda Suantara Pasek',
          photo: 'https://ik.imagekit.io/aryraditya/ceremonyku/ani-lusy-yuda/sa-00806.JPG?updatedAt=1650373251634&tr=w-484,h-605,fo-custom,cm-extract',
        },
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
    session() {
      const url = new URLSearchParams(window.location.search);
      const sesi = Number(url.get("sesi")) || 1;
      return sesi > SESSION.length ? 1 : sesi;
    }
  },
  mounted() {
    const [start, end] = SESSION[this.session - 1] || SESSION[0];

    this.time.start = this.$moment(start);
    this.time.end = end;

    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.overflow-fade-enter,
.overflow-fade-leave-to {
  opacity: 0;
}
</style>
